





























interface ComponentData {
  showList: boolean
  selectedElement: any
}
import Vue from 'vue'
export default Vue.extend({
  data(): ComponentData {
    return {
      showList: false,
      selectedElement: this.options[0]
    }
  },
  props: {
    options: {
      type: Array
    }
  },
  methods: {
    selectedChange(value: any): void {
      this.selectedElement = value
      this.showList = false
      console.log('select', value.data)

      this.$emit('change', value.data)
      this.$emit('input', value.data)
    }
  }
})
