



























import Vue from 'vue'
import OvaSelect from './OvaSelect.vue'

export default Vue.extend({
  components: {
    OvaSelect
  },
  props: {
    title: {
      type: String
    },
    price: {
      type: Number
    },
    subtitle: {
      type: String
    },
    options: {
      type: Array
    },
    icon: {
      type: String,
      default: 'help'
    },
    iconBgColor: {
      type: String
    }
  },
  methods: {
    selectedChange(value: string): void {
      this.$emit('input', value)
    }
  }
})
